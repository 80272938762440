export enum EnumVoucherPurpose {
  SALDO_POINT = 'REDEEM_TO_POINT',
  PARCEL_POINT = 'REDEEM_TO_LOYALTY_POINT',
  DISCOUNT_PERCENTAGE = 'DISCOUNT_BY_PERCENTAGE',
}

export const VOUCHER_PURPOSES = [
  {
    label: 'Redeem to Parcel Poin',
    value: EnumVoucherPurpose.PARCEL_POINT,
  },
  {
    label: 'Discount by Percentage',
    value: EnumVoucherPurpose.DISCOUNT_PERCENTAGE,
  },
]

export const VOUCHER_PURPOSES_EDIT = [
  {
    label: 'Redeem to Parcel Poin',
    value: EnumVoucherPurpose.PARCEL_POINT,
  },
  {
    label: 'Discount by Percentage',
    value: EnumVoucherPurpose.DISCOUNT_PERCENTAGE,
  },
  {
    label: 'Redeem to Point',
    value: EnumVoucherPurpose.SALDO_POINT,
  },
]

export const POINT_PURPOSES = [
  {
    label: 'Pick Up Regular',
    value: 'PICKUP',
  },
  {
    label: 'Drop Paket Regular',
    value: 'DROPOFF',
  },
  {
    label: 'Pick Up Favorite',
    value: 'PICKUP_FAVORITE',
  },
  {
    label: 'Drop Paket Favorite',
    value: 'DROPOFF_FAVORITE',
  },
  {
    label: 'BisaBelanja',
    value: 'SHOP',
  },
]

export enum EnumVoucherCodeType {
  SINGLE = 'SINGLE',
  MULTIPLE = 'MULTIPLE',
}

export enum EnumVoucherCodeMultipleType {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}

