import { PublicVoucherData, PublicVoucherPurposeData } from '@/domain/entities/PublicVoucher'
import {
  CreatePublicVoucherRequestInterface,
  UpdatePublicVoucherRequestInterface,
  DeletePublicVoucherRequestInterface,
} from '@/data/payload/contracts/PublicVoucherRequest'

export class CreatePublicVoucherRequest
  implements CreatePublicVoucherRequestInterface {
  public voucherName?: string
  public voucherCode?: string
  public limitPerDay?: number
  public voucherPurpose?: string
  public limitUsagePerCode?: number
  public limitUsagePerUser?: number
  public voucherData?: PublicVoucherData
  public startDateTime?: string
  public endDateTime?: string
  public voucherExpiryDateTime?: string
  public actorName?: string
  public voucherPurposeData?: PublicVoucherPurposeData
  public voucherPartnerCode?: string
  public multipleVoucherCount?: number

  constructor(
    voucherName?: string,
    voucherCode?: string,
    limitPerDay?: number,
    voucherPurpose?: string,
    limitUsagePerCode?: number,
    limitUsagePerUser?: number,
    voucherData?: PublicVoucherData,
    startDateTime?: string,
    endDateTime?: string,
    voucherExpiryDateTime?: string,
    actorName?: string,
    voucherPurposeData?: PublicVoucherPurposeData,
    voucherPartnerCode?: string,
    multipleVoucherCount?: number
  ) {
    this.voucherName = voucherName
    this.voucherCode = voucherCode
    this.limitPerDay = limitPerDay
    this.voucherPurpose = voucherPurpose
    this.limitUsagePerCode = limitUsagePerCode
    this.limitUsagePerUser = limitUsagePerUser
    this.voucherData = voucherData
    this.startDateTime = startDateTime
    this.endDateTime = endDateTime
    this.voucherExpiryDateTime = voucherExpiryDateTime
    this.actorName = actorName
    this.voucherPurposeData = voucherPurposeData
    this.voucherPartnerCode = voucherPartnerCode
    this.multipleVoucherCount = multipleVoucherCount
  }

  public toPayload(): string {
    const data = {
      public_voucher_name: this.voucherName,
      public_voucher_code: this.voucherCode,
      limit_per_day: this.limitPerDay,
      public_voucher_purpose: this.voucherPurpose,
      limit_usage_per_user: this.limitUsagePerUser,
      voucher_expiry_day: this.voucherData?.expiryDay,
      public_voucher_data: {
        point_amount: this.voucherData?.pointAmount,
      },
      start_date: this.startDateTime,
      end_date: this.endDateTime,
      actor_name: this.actorName,
      voucher_limit_usage: this.limitUsagePerCode,
      voucher_expired_at: this.voucherExpiryDateTime, // only for Discount by Percentage
      public_voucher_purpose_data: {
        discount_percentage: this.voucherPurposeData?.discountPercentage,
        discount_percentage_minimum_transaction: this.voucherPurposeData?.discountPercentageMinimumTransaction,
        discount_percentage_maximum_amount: this.voucherPurposeData?.discountPercentageMaximumAmount,
        shipment_type: this.voucherPurposeData?.shipmentType,
      },
      voucher_partner_code: this.voucherPartnerCode,
      multiple_voucher_count: this.multipleVoucherCount,
    }

    return JSON.stringify(data)
  }
}

export class UpdatePublicVoucherRequest
  implements UpdatePublicVoucherRequestInterface {
  public limitPerDay?: number
  public voucherPurpose?: string
  public limitUsagePerCode?: number
  public limitUsagePerUser?: number
  public voucherData?: PublicVoucherData
  public startDate?: string
  public endDate?: string
  public voucherExpiryDateTime?: string
  public actorName?: string
  public voucherPurposeData?: PublicVoucherPurposeData

  constructor(
    limitPerDay?: number,
    voucherPurpose?: string,
    limitUsagePerCode?: number,
    limitUsagePerUser?: number,
    voucherData?: PublicVoucherData,
    startDate?: string,
    endDate?: string,
    voucherExpiryDateTime?: string,
    actorName?: string,
    voucherPurposeData?: PublicVoucherPurposeData,
  ) {
    this.limitPerDay = limitPerDay
    this.voucherPurpose = voucherPurpose
    this.limitUsagePerCode = limitUsagePerCode
    this.limitUsagePerUser = limitUsagePerUser
    this.voucherData = voucherData
    this.startDate = startDate
    this.endDate = endDate
    this.actorName = actorName
    this.voucherExpiryDateTime = voucherExpiryDateTime
    this.voucherPurposeData = voucherPurposeData
  }

  public toPayload(): string {
    const data = {
      limit_per_day: this.limitPerDay,
      public_voucher_purpose: this.voucherPurpose,
      voucher_expiry_day: this.voucherData?.expiryDay,
      voucher_limit_usage: this.limitUsagePerCode,
      limit_usage_per_user: this.limitUsagePerUser,
      public_voucher_data: {
        point_amount: this.voucherData?.pointAmount,
      },
      start_date: this.startDate,
      end_date: this.endDate,
      actor_name: this.actorName,
      voucher_expired_at: this.voucherExpiryDateTime, // only for Discount by Percentage
      public_voucher_purpose_data: {
        discount_percentage: this.voucherPurposeData?.discountPercentage,
        discount_percentage_minimum_transaction: this.voucherPurposeData?.discountPercentageMinimumTransaction,
        discount_percentage_maximum_amount: this.voucherPurposeData?.discountPercentageMaximumAmount,
        shipment_type: this.voucherPurposeData?.shipmentType,
      },
    }

    return JSON.stringify(data)
  }
}

export class DeletePublicVoucherRequest
  implements DeletePublicVoucherRequestInterface {
  public actorName?: string

  constructor(actorName?: string) {
    this.actorName = actorName
  }

  public toPayload(): string  {
    const data = {
      actor_name: this.actorName,
    }

    return JSON.stringify(data)
  }
}
