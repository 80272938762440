


























import { Component, Prop, Vue } from 'vue-property-decorator'
import OptionBox from '@/app/ui/components/OptionBox/index.vue'

@Component({
  components: { OptionBox },
  inheritAttrs: false,
})
/**
 * name: string
 * Input radio name
 *
 * label: string
 * Label text
 *
 * inputValue: string
 * input value
 *
 */
export default class RadioButton extends Vue {
  @Prop({ required: true, type: String }) private name!: string
  @Prop({ required: true, type: String }) private label!: string
  @Prop({ required: true, type: String }) inputValue!: string
  @Prop({ default: false }) isDisabled!: boolean
  @Prop({ type: String }) private value!: string
  @Prop({ required: false, type: String }) private customClassLabel!: string

  get modelValue(): string {
    return this.value
  }

  set modelValue(value: string) {
    //
  }

  changeValue(event: Event): void {
    const target = event.target as HTMLInputElement
    this.$emit('input', target.value)
  }
}
