import { container } from 'tsyringe'
import Vue from 'vue'
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/app/ui/store'
import { Pagination } from '@/domain/entities/Pagination'
import {
  LocalStorage,
  Utils,
} from '@/app/infrastructures/misc'
import {
  PublicVoucher,
  PublicVoucherCodeMultipleExternalPartner,
  PublicVoucherData,
  PublicVoucherHistories,
  PublicVoucherHistory,
  PublicVoucherPurposeData,
  PublicVouchers,
} from '@/domain/entities/PublicVoucher'
import { PublicVoucherPresenter } from '@/app/ui/presenters/PublicVoucherPresenter'
import {
  CreatePublicVoucherRequest,
  DeletePublicVoucherRequest,
  UpdatePublicVoucherRequest,
} from '@/data/payload/api/PublicVoucherRequest'
import {
  PUBLIC_VOUCHER_HISTORY_PAGINATION,
  PUBLIC_VOUCHER_PAGINATION,
} from '@/app/infrastructures/misc/Constants'
import { EnumVoucherPurpose } from '@/app/infrastructures/misc/Constants/publicVoucher'
import {
  IParameter,
  IParameterMultipleCode,
  IPayloadCreatePublicVoucher
} from '@/data/infrastructures/misc/interfaces/publicVoucher'
import { DropDownLabelValue } from '@/app/ui/components/DropdownSelect/interface';

export interface PublicVoucherState {
  isLoading: boolean
  isDownloading: boolean
  publicVoucherData: PublicVoucher[]
  publicVoucherDetail: PublicVoucher
  paginationData: Pagination
  historyData: PublicVoucherHistory[] | undefined
  multipleVouchers: PublicVoucherHistory[] | undefined
  paginationHistory: Pagination
  publicVoucherCodePartner: PublicVoucherCodeMultipleExternalPartner[]
}

@Module({ namespaced: true, store, name: 'public-voucher', dynamic: true })
class PublicVoucherController extends VuexModule implements PublicVoucherState {
  private presenter: PublicVoucherPresenter = container.resolve(
    PublicVoucherPresenter
  )
  public isLoading = false
  public isDownloading = false
  public publicVoucherData: PublicVoucher[] = []
  public publicVoucherDetail = new PublicVoucher()
  public paginationData = new Pagination(1, PUBLIC_VOUCHER_PAGINATION, 0)
  public historyData: PublicVoucherHistory[] | undefined = []
  public paginationHistory = new Pagination(
    1,
    PUBLIC_VOUCHER_HISTORY_PAGINATION,
    0
  )
  public isSuccessSave = false
  public isDeleteSuccess = false
  public publicVoucherCodePartner: PublicVoucherCodeMultipleExternalPartner[] = []
  public multipleVouchers: PublicVoucherHistory[] | undefined = []

  @Action({ rawError: true })
  public getPublicVoucherList(params: Record<string, unknown>) {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getAll(formattedParams)
      .then(res => {
        this.setPublicVoucherData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Public Voucher Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getPublicVoucherDetail(publicVoucherId: string) {
    this.setLoading(true)

    this.presenter
      .get(publicVoucherId)
      .then(res => {
        this.setPublicVoucherDetail(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Public Voucher Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public createPublicVoucher(form: IPayloadCreatePublicVoucher ) {
    this.setLoading(true)
    const adminEmail = LocalStorage.getLocalStorage(
      LocalStorage.LOGIN_IDENTITY,
      true
    )

    this.presenter
      .create(
        new CreatePublicVoucherRequest(
          form.voucherName,
          form.voucherCode,
          1,
          form.voucherPurpose?.value,
          form.voucherLimit,
          form.voucherPerUser,
          new PublicVoucherData(
            form.voucherExpiry,
            form.voucherPointAmount,
            '',
            0
          ),
          form.redeemStartDateTime,
          form.redeemEndDateTime,
          form.voucherExpiryDateTime,
          adminEmail,
          new PublicVoucherPurposeData(
            form.discountPercentage,
            form.minTransaction,
            form.maxDiscountAmount,
            form.shipmentTypes.join(',')
          ),
          form.voucherPartnerCode,
          form.multipleVoucherCount,
        )
      )
      .then(() => {
        this.setSuccessSave(true)
      })
      .catch(error => {
        this.setSuccessSave(false)
        Vue.notify({
          title: 'Create Public Voucher Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public updatePublicVoucher(form: {
    publicVoucherId: string
    voucherLimit: number
    voucherPerUser: number
    voucherPurpose: DropDownLabelValue<string> | undefined
    voucherPointAmount: number
    discountPercentage: number,
    maxDiscountAmount: number,
    minTransaction: number,
    shipmentTypes: string[],
    voucherExpiry: number
    redeemStartDateTime: string
    redeemEndDateTime: string
    voucherExpiryDateTime: string
  }) {
    this.setLoading(true)
    this.setSuccessSave(false)
    const adminEmail = LocalStorage.getLocalStorage(
      LocalStorage.LOGIN_IDENTITY,
      true
    )

    this.presenter
      .update(
        form.publicVoucherId,
        new UpdatePublicVoucherRequest(
          form.voucherLimit,
          form.voucherPurpose?.value,
          form.voucherLimit,
          form.voucherPerUser,
          new PublicVoucherData(
            form.voucherExpiry,
            form.voucherPointAmount,
          ),
          form.redeemStartDateTime,
          form.redeemEndDateTime,
          form.voucherExpiryDateTime,
          adminEmail,
          new PublicVoucherPurposeData(
            form.discountPercentage,
            form.minTransaction,
            form.maxDiscountAmount,
            form.shipmentTypes.join(',')
          )
        )
      )
      .then(() => {
        this.setSuccessSave(true)
      })
      .catch(error => {
        Vue.notify({
          title: 'Update Public Voucher Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
        this.setSuccessSave(false)
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public deletePublicVoucher(form: { publicVoucherId: string }) {
    this.setLoading(true)
    this.setDeleteSuccess(false)

    const payload = new DeletePublicVoucherRequest(
      LocalStorage.getLocalStorage(LocalStorage.LOGIN_IDENTITY, true)
    )

    this.presenter
      .delete(form.publicVoucherId, payload)
      .then(() => {
        this.setDeleteSuccess(true)
      })
      .catch(error => {
        Vue.notify({
          title: 'Delete Public Voucher Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getVoucherHistory(form: { id: string; params: IParameter }) {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(form.params),
      'snake_case'
    )

    this.presenter
      .getHistories(form.id, formattedParams)
      .then(res => {
        this.setVoucherHistory(res)
      })
      .catch(error => {
        this.setVoucherHistory(
          new PublicVoucherHistories(this.paginationHistory, undefined)
        )
        Vue.notify({
          title: 'Fetch History Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getVoucherHistoryV2(form: { id: string; params: IParameter }) {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(form.params),
      'snake_case'
    )

    this.presenter
      .getHistoriesV2(form.id, formattedParams)
      .then(res => {
        this.setVoucherHistory(res)
      })
      .catch(error => {
        this.setVoucherHistory(
          new PublicVoucherHistories(this.paginationHistory, undefined)
        )
        Vue.notify({
          title: 'Fetch History Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getExportedFile(form: { id: string; params: IParameter }) {
    this.setDownloading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(form.params),
      'snake_case'
    )

    this.presenter
      .exportHistories(form.id, formattedParams)
      .then(res => {
        window.open(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Download Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setDownloading(false)
      })
  }

  @Action({ rawError: true })
  public getExportedFileV2(form: { id: string; params: IParameter }) {
    this.setDownloading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(form.params),
      'snake_case'
    )

    this.presenter
      .exportHistoriesV2(form.id, formattedParams)
      .then(res => {
        window.open(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Download Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setDownloading(false)
      })
  }

  @Action({ rawError: true })
  public getVoucherUsage(form: { id: string; params: IParameter }) {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(form.params),
      'snake_case'
    )

    this.presenter
      .get(form.id)
      .then(res => {
        this.setPublicVoucherDetail(res)
        if (res.purpose?.toUpperCase() === EnumVoucherPurpose.SALDO_POINT) {
          return this.presenter.getHistories(form.id, formattedParams)
        }
        return this.presenter.getHistoriesV2(form.id, formattedParams)
      })
      .then(resHistory => {
        this.setVoucherHistory(resHistory)
      })
      .catch(error => {
        this.setVoucherHistory(
          new PublicVoucherHistories(this.paginationHistory, [])
        )
        Vue.notify({
          title: 'Fetch History Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getVoucherCodeMultipleExternalPartner() {
    this.setLoading(true)
    this.presenter
    .getPublicVoucherCodePartner()
    .then(res => {
      this.setPublicVoucherMultipleExternalPartner(res)
    })
    .catch(error => {
      Vue.notify({
        title: 'Fetch Public Voucher Code List Partner Failed',
        text:
          error.status === 400 || error.status === 422
            ? error.error.message.en
            : 'Something wrong',
        type: 'error',
        duration: 5000,
      })
    })
    .finally(() => {
      this.setLoading(false)
    })
  }

  @Action({ rawError: true })
  public getMultipleVouchers(form: { params: IParameterMultipleCode }) {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(form.params),
      'snake_case'
    )

    this.presenter
      .getMultipleVouchers(formattedParams)
      .then(res => {
        this.setMultipleVouchers(res)
      })
      .catch(error => {
        this.setMultipleVouchers(
          new PublicVoucherHistories(this.paginationHistory, undefined)
        )
        Vue.notify({
          title: 'Fetch List Public Multiple Vouchers Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something went wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getExportFileMultipleVoucher(form: { params: IParameterMultipleCode }) {
    this.setDownloading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(form.params),
      'snake_case'
    )

    this.presenter
    .exportPublicMultipleVoucherHistories(formattedParams)
    .then(res => {
      window.open(res)
    })
    .catch(error => {
      Vue.notify({
        title: 'Download Failed',
        text:
          error.status === 400 || error.status === 422
            ? error.error.message.en
            : 'Something wrong',
        type: 'error',
        duration: 5000,
      })
    })
    .finally(() => {
      this.setDownloading(false)
    })
  }

  @Mutation
  private setPublicVoucherData(publicVouchers: PublicVouchers) {
    this.paginationData = <Pagination>publicVouchers.pagination
    this.publicVoucherData = <PublicVoucher[]>publicVouchers.data
  }

  @Mutation
  private setVoucherHistory(histories: PublicVoucherHistories) {
    this.paginationHistory = <Pagination>histories.pagination
    this.historyData = <PublicVoucherHistory[]>histories.data
  }

  @Mutation
  private setPublicVoucherDetail(publicVoucher: PublicVoucher) {
    this.publicVoucherDetail = publicVoucher
  }

  @Mutation
  private setLoading(bool: boolean) {
    this.isLoading = bool
  }

  @Mutation
  private setDownloading(bool: boolean) {
    this.isDownloading = bool
  }

  @Mutation
  public setSuccessSave(isSuccess: boolean) {
    this.isSuccessSave = isSuccess
  }

  @Mutation
  public setDeleteSuccess(isSuccess: boolean) {
    this.isDeleteSuccess = isSuccess
  }

  @Mutation
  public setPublicVoucherMultipleExternalPartner(data: PublicVoucherCodeMultipleExternalPartner[]) {
    this.publicVoucherCodePartner = data
  }

  @Mutation
  public setMultipleVouchers(histories: PublicVoucherHistories) {
    this.multipleVouchers = histories.data
    this.paginationHistory = <Pagination>histories.pagination
  }
}

export default getModule(PublicVoucherController)
